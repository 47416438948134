
    import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
    import { Route, RawLocation } from "vue-router";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import moment from "moment";
    import ReservationPayload from "@/store/modules/reservation/models";
    import RoutesEnum from "@/router/routesEnum";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import CustomerProject from "@/models/customerProject";
    import { PreviewClientQuery } from "@/models/previewClientQuery";
    import { ProductOptionModel, ProductOptionQuery } from "@/apiManager/product/productData";
    import { ReservationBody, ReservationBodyOption, ReservationModel, ReservationOptionModel } from "@/apiManager/reservation/reservationData";
    import { ProductUnitEnum } from "@/apiManager/_common/enums";

    //#region validateQuery
    let validateQuery = (p_Query: PreviewClientQuery): boolean =>
    {
        if ((p_Query.prod === undefined) || (p_Query.prodPDat === undefined) || (p_Query.prodPCla === undefined) || (p_Query.prodPCat === undefined) || (p_Query.pricCla === undefined) || (p_Query.pricCat === undefined) || (p_Query.dtDep === undefined) || (p_Query.dtRet === undefined) || (p_Query.nbA === undefined) || (p_Query.nbC === undefined) || (p_Query.nbR === undefined))
        {
            return false;
        }
        if ((p_Query.prod.length === 0) || (p_Query.dtDep.length === 0) || (p_Query.dtRet.length === 0))
        {
            return false;
        }
        if ((p_Query.prodPDat < 1) || (p_Query.prodPCla < 1) || (p_Query.prodPCat < 1) || (p_Query.pricCla < 1) || (p_Query.pricCat < 1) || (p_Query.nbA < 1) || (p_Query.nbC < 0) || (p_Query.nbR < 1))
        {
            return false;
        }
        if ((!moment(p_Query.dtDep as string).isValid()) || (!moment(p_Query.dtRet as string).isValid()))
        {
            return false;
        }

        return true;
    };
    //#endregion

    @Component
    export default class Options extends Mixins(BeforeRouteEnterMixin)
    {
        private product: string = "";
        private productPriceDateCode: number = 0;
        private productPriceClassCode: number = 0;
        private productPriceCategoryCode: number = 0;
        private pricingClassCode: number = 0;
        private pricingCategoryCode: number = 0;
        private dateDepart: string = "";
        private dateReturn: string = "";
        private nbAdult: number = 0;
        private nbChild: number = 0;
        private nbRoom: number = 0;
        private listOption: ProductOptionModel[] = [];
        private reservation: ReservationModel = new ReservationModel();
        private listOptionSelected: number[] = [];
        private busPickup: number = 0;

        @Prop({ type: String, default: "" }) private readonly productGroupSelected!: string;

        get components()
        {
            let components =
            {
                "format-money": FormatMoney
            };

            return components;
        }

        get getCurrency(): string
        {
            return this.listOption.length > 0 ? this.listOption[0].currency : "";
        }

        get getOptionTotalOptions(): number
        {
            let total: number = 0;

            this.listOptionSelected.forEach(
                (optionCode: number) =>
                {
                    let option: ProductOptionModel | undefined = this.listOption.find((optionInfo) =>
                    {
                        return optionInfo.code === optionCode;
                    });

                    if (option)
                    {
                        // total += option.total;
                        total += (option.price + option.taxes) * option.quantity;
                    }
                }
            );

            return total;
        }

        get getOptionDepositOptions()
        {
            let total: number = 0;

            this.listOptionSelected.forEach(
                (optionCode: number) =>
                {
                    let option: ProductOptionModel | undefined = this.listOption.find((optionInfo) =>
                    {
                        return optionInfo.code === optionCode;
                    });

                    if (option)
                    {
                        total += option.deposit;
                    }

                }
            );

            return total;
        }

        get getTotalProductWithOptions(): number
        {
            return this.reservation.total + this.getOptionTotalOptions;
        }

        get getDepositProductWithOptions(): number
        {
            return this.reservation.deposit + this.getOptionDepositOptions;
        }

        getOptionClassCategory(p_Option: ProductOptionModel): string
        {
            let result: string = "( ";
            let pricingClass: string = this.translateValue(p_Option.pricingClass);
            let pricingCategory: string = this.translateValue(p_Option.pricingCategory);

            if (pricingClass !== "Standard" && pricingClass.length > 0)
            {
                result = `${ result } ${ pricingClass }`;
            }

            if (pricingCategory !== "Standard" && pricingCategory.length > 0)
            {
                if (result !== "( ")
                {
                    result = `${result} - `;
                }

                result = `${ result } ${ pricingCategory }`;
            }

            if (result !== "( ")
            {
                result = `${result} )`;
            }
            else
            {
                result = "";
            }

            return result;
        }

        getOptionUnit(p_Option: ProductOptionModel): string
        {
            switch (p_Option.unit)
            {
                case ProductUnitEnum.PerPax:
                    return this.i18n(this.getRezToursLanguageEnum.perPassenger);
                case ProductUnitEnum.PerRoom:
                    return this.i18n(this.getRezToursLanguageEnum.perRoom);
                case ProductUnitEnum.PerPaxPerDay:
                    return this.i18n(this.getRezToursLanguageEnum.perPaxPerDay);
                case ProductUnitEnum.PerRoomPerDay:
                    return this.i18n(this.getRezToursLanguageEnum.perRoomPerDay);
                default:
                    return "";
            }
        }

        getOptionQuantityVisible(p_Option: ProductOptionModel): boolean
        {
            return (p_Option.unit === ProductUnitEnum.PerQuantity);
        }

        getOptionPrice(p_Option: ProductOptionModel): number
        {
            return p_Option.price + p_Option.taxes;
        }

        getOptionCheckDisabled(p_Option: ProductOptionModel): boolean
        {
            if ((p_Option.hasInventory) && (p_Option.availability < p_Option.nbRequested))
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        onQuantityAdd(p_Option: ProductOptionModel)
        {
            p_Option.quantity++;

            // if (this.listOptionSelected.findIndex(element => element === p_Option.code) < 0)
            // {
            //     this.listOptionSelected.push(p_Option.code);
            // }
        }

        onQuantityRemove(p_Option: ProductOptionModel)
        {
            p_Option.quantity = Math.max(p_Option.quantity - 1, 1);

            let index: number = this.listOptionSelected.findIndex((element) =>
                {
                    return element === p_Option.code;
                });
            if (index < 0)
            {
                this.listOptionSelected.push(p_Option.code);
            }
        }

        onBackClick()
        {
            this.$router.push({
                name: RoutesEnum.ProductDetail,
                query:
                {
                    prod: this.product,
                    prodPDat: this.productPriceDateCode.toString(),
                    dtDep: this.dateDepart,
                    dtRet: this.dateReturn
                }
            });
        }

        onNextClick()
        {
            let listOptionSelected: ReservationBodyOption[] = [];
            let optionSelected: ReservationBodyOption;

            this.listOptionSelected.forEach(
                (optionCode: number) =>
                {
                    let option: ProductOptionModel | undefined = this.listOption.find((optionInfo) =>
                    {
                        return optionInfo.code === optionCode;
                    });

                    if (option)
                    {
                        // total += option.total;
                        optionSelected = new ReservationBodyOption();
                        optionSelected.code = option.code;
                        optionSelected.quantity = option.quantity;
                        listOptionSelected.push(optionSelected);
                    }
                }
            );

            try
            {
                let queryData: PreviewClientQuery = {
                    prod: this.product,
                    prodPDat: this.productPriceDateCode,
                    prodPCla: this.productPriceClassCode,
                    prodPCat: this.productPriceCategoryCode,
                    pricCla: this.pricingClassCode,
                    pricCat: this.pricingCategoryCode,
                    dtDep: this.dateDepart,
                    dtRet: this.dateReturn,
                    nbA: this.nbAdult,
                    nbC: this.nbChild,
                    nbR: this.nbRoom,
                    opt: listOptionSelected,
                    pickup: this.busPickup
                };

                this.$router.push({
                    name: RoutesEnum.TravelBag,
                    query: {
                        data: encodeURIComponent(btoa(JSON.stringify(queryData)))
                    },
                    params: {
                        productGroupSelected: this.productGroupSelected
                    }
                });
            }
            catch
            {

            }
        }

        beforeRouteEnter(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (p_To.query.code)
            {
                return p_Next();
            }

            let queryData: PreviewClientQuery = JSON.parse(atob(decodeURIComponent(p_To.query.data?.toString() ?? "")));
            if (validateQuery(queryData))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        beforeRouteUpdate(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (p_To.query.code)
            {
                return p_Next();
            }

            let queryData: PreviewClientQuery = JSON.parse(atob(decodeURIComponent(p_To.query.data?.toString() ?? "")));
            if (validateQuery(queryData))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        async beforeMount()
        {
            this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(true));

            let queryData: PreviewClientQuery = JSON.parse(atob(decodeURIComponent(this.$route.query.data?.toString() ?? "")));

            this.product = queryData?.prod ?? "";
            this.productPriceDateCode = queryData?.prodPDat ?? 0;
            this.productPriceClassCode = queryData?.prodPCla ?? 0;
            this.productPriceCategoryCode = queryData?.prodPCat ?? 0;
            this.pricingClassCode = queryData?.pricCla ?? 0;
            this.pricingCategoryCode = queryData?.pricCat ?? 0;
            this.dateDepart = queryData?.dtDep ?? "";
            this.dateReturn = queryData?.dtRet ?? "";
            this.nbAdult = queryData?.nbA ?? 0;
            this.nbChild = queryData?.nbC ?? 0;
            this.nbRoom = queryData?.nbR ?? 0;
            this.busPickup = queryData?.pickup ?? 0;

            this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(true));

            try
            {
                let query: ProductOptionQuery = new ProductOptionQuery(this.product, this.dateDepart, this.dateReturn, this.pricingClassCode, this.pricingCategoryCode, this.nbAdult + this.nbChild, this.nbRoom);
                this.listOption = await this.$apiManager.product.listOptionAsync(query);

                let listOptionSelected: ReservationBodyOption[] = queryData?.opt ?? [];
                let option: ProductOptionModel;
                let optionSelected: ReservationBodyOption;
                for (option of this.listOption)
                {
                    for (optionSelected of listOptionSelected)
                    {
                        if (optionSelected.code === option.code)
                        {
                            option.quantity = optionSelected.quantity;
                            this.listOptionSelected.push(option.code);
                            break;
                        }
                    }
                }
            }
            catch
            {

            }

            let body: ReservationBody = new ReservationBody();
            body.product = this.product;
            body.productPriceDateCode = this.productPriceDateCode;
            body.productPriceClassCode = this.productPriceClassCode;
            body.productPriceCategoryCode = this.productPriceCategoryCode;
            body.pricingClassCode = this.pricingClassCode;
            body.pricingCategoryCode = this.pricingCategoryCode;
            body.dateDepart = this.dateDepart;
            body.dateReturn = this.dateReturn;
            body.nbAdult = this.nbAdult;
            body.nbChild = this.nbChild;
            body.nbRoom = this.nbRoom;
            body.busPickup = this.busPickup;
            body.project = CustomerProject.Get();
            body.listOption = [];

            try
            {
                this.reservation = await this.$apiManager.reservation.getPreviewAsync(body);

                let option: ReservationOptionModel;
                for (option of this.reservation.listOption)
                {
                    this.reservation.total -= option.total;
                }
            }
            catch(ex)
            {
            }

            this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(false));

            if (this.listOption.length === 0)
            {
                let queryData: PreviewClientQuery = {
                    prod: this.product,
                    prodPDat: this.productPriceDateCode,
                    prodPCla: this.productPriceClassCode,
                    prodPCat: this.productPriceCategoryCode,
                    pricCla: this.pricingClassCode,
                    pricCat: this.pricingCategoryCode,
                    dtDep: this.dateDepart,
                    dtRet: this.dateReturn,
                    nbA: this.nbAdult,
                    nbC: this.nbChild,
                    nbR: this.nbRoom,
                    opt: [],
                    pickup: this.busPickup
                };

                this.$router.push({
                    name: RoutesEnum.TravelBag,
                    query: {
                        data: encodeURIComponent(btoa(JSON.stringify(queryData)))
                    },
                    params: {
                        productGroupSelected: this.productGroupSelected
                    }
                });
            }

            this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(false));
        }
    }
